.logo{
  height: 3rem;
  width: 10rem;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-ul{
  list-style: none;
  display: flex;
  gap: 2rem;
}
.header-list a{
  color: white;
  text-decoration: none;
}
.header-list a:hover{
color: var(--orange);
}
