.plans{
  padding: 0 2rem;
  position: relative;
}
.plan-blur-1{
  width: 22rem;
  height: 30rem;
  left: 0;
  top: 6rem;
}
.plan-blur-2{
  width: 22rem;
  height: 30rem;
  right: 0;
  top: 10rem;
}
.plans-haeding{
  color: #fff;
  display: flex;
  font-size: 3rem;
  font-style: italic;
  font-weight: 700;
  gap: 5rem;
  justify-content: center;
  text-transform: uppercase;
  gap: 2rem;
  align-items: center;
  padding-bottom: 4rem;
}
.plan{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;

}
.planed{
  display: flex;
  flex-direction: column;
  background-color: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}
.planed:nth-child(2){
  background: var(--planCard);
  transform: scale(1.1);
}
.plan-img{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.icon{
  fill: var(--orange);
  height: 2rem;
  width: 2rem;
}
.name{
      font-size: 1rem;
    font-weight: 700;
}
.price{
  font-size: 3rem;
  font-weight: 700;
}
.plan-img>:nth-child(1){
  width: 1rem;
}
.plan-img>:nth-child(2){
  color: #fff;
}
.plans-list{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.iconss{
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}