.join-us{
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}
.left-j{
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
}
.left-j>:nth-child(1),
.left-j>:nth-child(2){
  display: flex;
  gap: 1rem;
  align-items: center;
}
.left-j>hr{
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0;
  position: absolute;
  width: 10.5rem;
}
.right-j{
  align-items: flex-end;
  display: flex;
  justify-content: center;
}
.input{
  background-color: gray;
  display: flex;
  gap: 3rem;
  padding: 1rem 2rem;
}
.input>input{
  background-color: initial;
  border: none;
  color: var(--lightgray);
  outline: none;
}
::placeholder {
  color: var(--lightgray);
  opacity: 1; 
}
.input>button{
  background-color: var(--orange);
  color: #fff;

}