.programs{
  /* border: 1px solid pink; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
}
.programs-header{
  color: #fff;
  display: flex;
  font-size: 3rem;
  font-style: italic;
  font-weight: 700;
  gap: 5rem;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
}
.Program-cetagory{
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}