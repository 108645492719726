.Hero{
  display: flex;
}
.hero-blur{
  height: 30rem;
  width: 22rem;
  left: 0;
}
/* Left section */
.hero-left{
  /* border: 1px solid white; */
  padding: 1.5rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  flex: 3;
}
.bar{
  background-color: #363d42;
  border-radius: 4rem;
  margin-top: 4rem;
  max-width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
}
.bar>div{
  background-color: var(--orange);
  position: absolute;
  width: 5.4rem;
  height: 80%;
  /* border: 1px solid green; */
  border-radius: 3rem;
  left: 8px;
  clear: both;
  z-index: -2;
  color: white;
}
/* HeroText */
.main-text{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.main-text-1{
font-size: 4.5rem;
text-transform: uppercase;
font-weight: bold;
color: white;
}
.main-text-2{
font-size: 4.5rem;
text-transform: uppercase;
font-weight: bold;
color: white;
}
.hero-dec{
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 1px;
  text-transform: none;
  width: 70%;
  color: rgb(223, 222, 222);
}

.figures{
  display: flex;
  gap: 2rem;
}
.members{
  display: flex;
  flex-direction: column;
}
.numbers{
  color: #fff;
    font-size: 2rem;
}
.figure-text{
  color: var(--gray);
  text-transform: uppercase;
}
.hero-btns{
  display: flex;
  gap: 1rem;
}
.hero-btns>:nth-child(1){
  background-color: var(--orange);
  color: white;
  width: 8rem;
}
.hero-btns>:nth-child(1):hover{
  background-color: initial;
  box-shadow:inset 0 0 0 2px var(--orange);
  color: #fff;
  width: 8rem;
}
.hero-btns>:nth-child(2){
  background-color: initial;
  border: 2px solid var(--orange);
  color: #fff;
  width: 8rem;
}
.hero-btns>:nth-child(2):hover{
  background-color: var(--orange);
  color: white;
  width: 8rem;
}


/* Right section */
.hero-right{
  /* border: 1px solid rgb(255, 41, 41); */
  flex: 1;
  background-color: var(--orange);
  position: relative;
}
.header-btn{
  background-color: #fff;
  border: 4px solid transparent;
  font-weight: 700;
  padding: 0.5rem;
  transition: all .3s;
  color: #000;
  position: absolute;
  right: 3rem;
  top: 2rem;
  cursor: pointer;
}
.heart{
  align-items: start;
  background-color: var(--darkGrey);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  position: absolute;
  right: 4rem;
  top: 7rem;
}
.heart>:nth-child(1){
  width: 2rem;
}
.heart-rate{
  color: var(--gray);
}
.heart-number{
  color: #fff;
    font-size: 1.5rem;
}
.hero-image{
  position: absolute;
  right: 8rem;
  top: 10rem;
  width: 23rem;
}
.hero-img>:nth-child(2){
  position: absolute;
  right: 20rem;
  top: 4rem;
  width: 15rem;
  z-index: -1;
}
.calories{
  background-color: var(--caloryCard);
  border-radius: 5px;
  display: flex;
  gap: 2rem;
  padding: 1rem;
  position: absolute;
  top: 32rem;
  right: 28rem;
  width: fit-content;
}
.cal-text>:nth-child(1){
  color: var(--gray);
}
.cal-text>:nth-child(2){
  color: #fff;
  font-size: 1.5rem;
}


