.Chose{
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.chose-img{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
.chose-img>:nth-child(1){
  grid-row: 1/ span 3;
  height: 28rem;
  width: 12rem;

}
.chose-img>:nth-child(2){
  grid-column: 2/ span 3;
  height: 16rem;
  width: auto;

}
.chose-img>:nth-child(3){
  width: 14rem;
}
.chose-img>:nth-child(4){
  height: 11.2rem;
  width: 10rem;
}

.chose-right{
  /* border: 1px solid saddlebrown; */
  display: flex;
  flex-direction: column;
  gap: 1rem;  
}
.chose-right>:nth-child(1){
  color: var(--orange);
  font-weight: 700;
}
.chose-right>:nth-child(2){
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
}
.list>:nth-child(1){
  height: 2rem;
  width: 2rem;
}
.lists{
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.list{
  color: #fff;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.chose-right>:nth-child(4){
  color: var(--gray);
  font-weight: normal;
}
.partners{
  display: flex;
  gap: 1rem;
}
.partners>img{
  width: 2.5rem;
}