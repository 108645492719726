
.footers>hr{
  border: 1px solid var(--lightgray);
}
.footer{
  align-items: center;
    flex-direction: column;
    height: 20rem;
    justify-content: center;
    padding: 1rem 2rem;
    display: flex;
    gap: 4rem;
    position: relative;
}
.footer-blur-1{
  background-color: red;
  filter: blur(200px);
  bottom: 0;
  right: 6rem;
  height: 12rem;
  width: 26rem;
}
.footer-blur-2{
  filter: blur(200px);
  bottom: 0;
  left: 6rem;
  height: 12rem;
  width: 30rem;
  background-color: var(--orange);
}
.logos{
  display: flex;
  gap: 4rem;
}
.logos>img{
  cursor: pointer;
  height: 2rem;
  width: 2rem;
}
.footer-icon>img{
  width: 10rem;
}