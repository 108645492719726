.program-card {
  background-color: gray;
  color: #fff;
  /* flex: 1 1; */
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  display: flex;
  gap: 1rem;
}
.program-card:hover {
  cursor: pointer;
  background: linear-gradient(210.41deg, rgba(250,80,66) 1.14% , rgba(255,167,57) 100.75%);
}
.join-now {
  align-items: center;
  display: flex;
  gap: 2rem;
}
.program-card > :nth-child(1) {
  fill: #fff;
  height: 2rem;
  width: 2rem;
}
.program-card > :nth-child(2) {
  font-size: 1rem;
  font-weight: 700;
}
.program-card > :nth-child(3) {
  font-size: 0.9rem;
  line-height: 25px;
}
.join-now > img {
  width: 1rem;
}
