.testmonials{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2rem;
  gap: 1rem;
}
.left{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: #fff;
}
.left>:nth-child(1){
  color: var(--orange);
  font-weight: 700;
  text-transform: uppercase;
}
h2{
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;

}
.slider{
  letter-spacing: 2px;
    line-height: 40px;
    text-align: justify;
}
.names{
  display: flex;
  gap: 0.5rem;
  text-transform: uppercase;

}
.names>:nth-child(1){
  color: var(--orange);
}
.right{
  position: relative;
}
.right-img{
  object-fit: cover;
  right: 8rem;
  top: 2rem;
  height: 20rem;
  position: absolute;
  width: 17rem;
}
.right>:first-child{
  background-color: initial;
  border: 2px solid orange;
  right: 9rem;
  top: 0.9rem;
  height: 20rem;
  position: absolute;
  width: 17rem;
}
.right>:nth-child(2){
  background: var(--planCard);
  height: 19rem;
  position: absolute;
  right: 7rem;
  top: 4rem;
  width: 17rem;
}
.arrow{
  display: flex;
  gap: 1rem;
  left: 3rem;
  position: absolute;
  bottom: 1rem;
}
.arrow>img{
  cursor: pointer;
  width: 1.5rem;
}